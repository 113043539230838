.item-status-container {
    align-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.status-item {
    align-items: center;
    margin-top: 10px; 
    height: 40px; 
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border: 1px solid grey;
    box-shadow: 1px;

    &-icon {
        width: 5%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 2em;
            height: 2em; 
        }
    }

    &-name {
        width: 40%;
    }

    &-update {
        width: 30%;
    }

    &-status {
        width: 5%;
        align-items: center;
        justify-content: center;
        display: flex;
        color: green; 

        svg {
            width: 2em;
            height: 2em; 
        }

    }
}

.alarm {
    color: red;
}

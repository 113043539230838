.quadrant-view {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px;
    height: 100%;
}

.quadrant {
    background-color: aliceblue;
    margin: 5px; 
}

.quadrant-header {
    align-items: center;
    text-align: center;
    font-weight: bold;
}
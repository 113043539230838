.photo-capture-container {
    width: 500px;
    height: 300px;
}

.canvas-container {
    width: 200px;
    height: 200px;
    // z-index: 500;
}

.video-area {
    width: 100%;
    height: 100%;
}

.show-camera {
    visibility: visible;
}

.hide-camera {
    display: none;
}

.area-display {
    visibility: visible;
}

.area-hide {
    display: none;
}

.preview-image {
    width: 100%;
    height: 100%;
}
.customer-navigation-container {

    .profile-photo {
        height: 25%;
        background-color: cornflowerblue;
        display: flex;
        justify-content: center;
    
        img {
            height: 250px;
            // margin: auto;
            // width: 25%;
        }
    }

    .sidebar-nav-item-container {
        // padding: 20px 10px;

        .sidebar-nav-item {
            // padding-left: 10px;
            padding: 20px 10px;
            margin-left: 20px;

            background-color: white;
            border-bottom: 1px solid grey;
        }
    }

}

.focus {
    background-color: black;
}

.unfocus {
    background-color: grey;
}

.focus:hover, .unfocus:hover {
    cursor: pointer;
}



.customer-profile-content {
    padding: 50px;
    height: 100%;
}

.customer-profile {
    &-details {
        height: 100%;
    }

    &-comments {
        width: 100%;
        height: 85vh;

        tr {
            border-bottom: 1px solid grey;
        }

        td {
            width: 15%;
        }

        .comment-content {
            width: 100%;
            display: flex;
            overflow: hidden;

            .comment {
                width: 90%;
            }

            .toggle {
                width: 5%;
                height: auto;
                align-content: center;
            }

        }
    }

    &-multimedia {
        display: inline-block;
        // justify-content: justify-content;
        height: 80%;
        width: 100%;
        overflow: hidden;
        padding: 1%;
        background-color: blue;

        .customer-image {
            display: inline-block;
            width: 250px;
            height: 250px;
            padding: 10px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-history {
        width: 100%;
        height: 100%;

        tr {
            border-bottom: 1px solid grey;
            overflow: hidden;
        }

        td {
            width: 15%;
            overflow: hidden;
            white-space: nowrap;
        }
    }

}

.loan-OPEN {
    background-color: green;
}

.loan-OVERDUE {
    background-color: red;
}

.loan-CLOSED {
    background-color: grey;
}

.long {
    height: auto;
}

.short {
    height: 50px;
}

.customer-details, .customer-stats, .customer-address, .loan-stats {
    padding: 10px;
    border: 1px solid black;
} 

.customer-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // background-color: bisque;
    border: 1px solid black;

    div {
        display: flex; 
        width: 100%; 
    }
}

// .customer-stats {
    

// }

// .customer-address {

// }

// .loan-stats {

// }


// new area
.content-customer {
    padding: 10px; 
    height: 100vh;
    width: 100%;
    // display: grid;
}

.status-customer {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
}

.section {
    width: 30%;
    display: flex;
    justify-content: flex-start;
    // background-color: beige;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    span {
        display: block;
        width: 100%;
        padding: 2px 0px;
    }
}

.section-header {
    width: 40%;
    font-weight: bold;
}

.section-content {
    width: 60%;
    display: block;

    // .icon-good, .icon-alarm {
    //     display: flex;
    //     width: 100%; 
    // }
    span {
        display: flex;
    }
}

.details-customer-wrapper {
    margin-top: 20px; 
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.left-side-customer, .right-side-customer {
    display: block;
}

.left-side-customer {
    width: 30%;
}

.right-side-customer {
    margin-left: 15px;
}

.details-customer, .notes-customer {
    width: 100%;
    height: 35vh;
}

.details-customer {
    // background-color: bisque;
    display: flex;
    justify-content: space-evenly;
    padding: 10px;
    margin: 7% 0 10px 0px;  
    border:1px solid $tab-border;

    .profile-image {
        display: flex;
    }

    .details-customer-credentials {
        margin-left: 10px;
        width: 50%;

        label {
            width: 100%;
            font-weight: bold;
        }

        input {
            height: 10%;
            margin-bottom: 10px; 
        }
    }
}

.notes-customer {
    // overflow: scroll;
}

.right-side-customer {
    width: 70%;
}

.content-customer-footer {
    // margin: auto;
    width: 99%;
    height: 75px; 
    z-index: 100;
    position: fixed;
    bottom: 0%; 
    background-color: #868686;
    display: flex;
    // justify-content: flex-start;
    // text-align: center;
    align-items: center;

    // .btn {
    //     display: inline-block; 
    // }
}

.description-customer {
    width: 100%;
    height: 150px;   
}

.tab-view-customer {
}

.search-header {
    width: 100%;
    display: flex;
    justify-content: right;
}

.customer-item-focus-container {
    width: 98%;
    height: 35vh;
    margin: auto;
    margin-top: 40px; 
    border: 1px solid $tab-border;
    border-radius: 5px;
}

.customer-item-focus-image, .customer-item-focus-loan, .customer-item-focus-audit {
    // background-color: blue;
    width: 100%;
}

.customer-item-focus {
    &-image {
        // display: flex;
        display: grid;
        // grid-template-columns: repeat(2, 50%);
        grid-template-columns: 30% 70%;
        grid-template-rows: auto;
        grid-template-areas: 
            "image name"
            "image uploaded"
            "image description";
        // grid-template-rows: 70;
        .image-detail {
            width: 60%;
            // height: auto;
            margin: 5px; 
        }
        .item-a {
            grid-area: image;
            margin: auto; 
        }

        .item-b {
            grid-area: name;
            margin: 5px; 
        }

        .item-c {
            grid-area: uploaded;
            margin: 5px; 
        }

        .item-d {
            grid-area: description;
            margin: 5px;
        }

    }

    &-loan {
        .input-label-above {
            margin-bottom: 5px; 
        }
    }
    &-audit {}
}

.flex-display {
    display: flex;
}

.distribute-align {
    justify-content: space-between;
}

.width-card {
    width: 20%;
}
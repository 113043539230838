.over-page-loading {
    background-color: rgba(195, 190, 190, 0.4);
    min-height: 100vh;
    min-width: 96vw;
    position: fixed;
    z-index:1100;

    &-content {
        width: fit-content;
        height: fit-content;
        // background-color: white;
        margin: auto;
        margin-top: 150px;
        // box-shadow: 5px 10px 8px #c7c2c2;
        filter: drop-shadow(8px 8px 4px rgb(9, 80, 2));
    }
}

.show-loading {
    visibility: visible;
}

.hide-loading {
    visibility: hidden;
}
.loan-dashboard-container {
    align-content: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // background-color: blue;
}

.loan-dashboard-reliability {
    align-items: center;
    text-align: center;
    width: 100%; 

    svg {
        width: 3%;
        height: auto;
    }
}

.loan-dashboard-payment, .loan-dashboard-visits {
    width: 100%;
    display: block;

    .grid-container {
        width: 100%; 
        display: flex; 
    }
}

.cashreg-Header { grid-area: header; }
.cashreg-Main { grid-area: main; }
.cashreg-Footer { grid-area: footer; }

.cashreg-grid-container {
    padding: 30px;
    display: grid;
    grid-template:
    'header'
    'main'
    'footer';
    grid-gap: 30px;
    //background-color: #2196F3;
  }

.cashreg-grid-container-head {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 80px;
}

.cashreg-grid-container-body {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    gap: 30px;
}

.cashreg-grid-container-btns {
    display: flex;
    position: relative;
    align-items: stretch;
    float: right;
}
.cashreg-grid-container-btns > div {
    float: right;
}

.cashreg-total-box > div {
    float: right;
    position: relative;
    width: fit-content;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 1.25rem;
    padding: 10px;
}

.cashreg-btn {
    border: none;
    background: transparent;
    float: right;
    color: blue;

}

.cashreg-btn.focus, .cashreg-btn:focus {
    outline: 0;
    // box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.cashreg-btn:focus, .cashreg-btn:hover {
    text-decoration: underline;
    color: red;
}
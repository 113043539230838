
.administration-page {
    padding: 50px;
}

.administration {
    &-block {
        text-align:center;
        tr {
            border-bottom: 1px solid grey;
        }

        td {
            width: 13%;
        }
    }
}

.over-page-modal {
    background-color: transparent;
    min-height: 100vh;
    min-width: 97vw;
    // max-width: 100%;
    position: fixed;
    z-index: 100;
    // margin: auto;
    

    &-content {
        width: fit-content;
        height: fit-content;
        background-color: white;
        margin: auto;
        margin-top: 150px;
        box-shadow: 5px 10px 8px #888888;
    }
}

.show-modal {
    visibility: visible;
}

.hide-modal {
    visibility: hidden;
}

// -webkit-box-pack: center;
// -ms-flex-pack: center;
// -webkit-box-orient: vertical;
// -webkit-box-direction: normal;
// background: var(--background-secondary);
// border: var(--modal-border);
// border-radius: 4px;
// -webkit-box-shadow: var(--modal-box-shadow);
// box-shadow: var(--modal-box-shadow);
// color: var(--modal-text--primary);
// display: -webkit-box;
// display: -ms-flexbox;
// display: flex;
// -ms-flex-direction: column;
// flex-direction: column;
// justify-content: center;
// max-width: 990px;
// min-height: 200px;
// min-width: 465px;
// outline: none;
// z-index: var(--modals-z-index);
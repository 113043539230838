.error-text {
    color: $error;
}

.error-list-item {
    list-style-type: none;
}

.hide-error-message {
    visibility: none;
}
//Around entire routes
.primary-content-wrapper {
    height: 100vh;
    background-color: white;
}

//Only around area where content will be shown
.content-wrapper {
    // padding-top: px;
    display: block;
    padding-top: 70px;
}
.text-bold {
    font-weight: bold;
}

.layout-margin {
    margin: 3px;
}

.hide {
    display: none;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

.align-left {
    text-align: left;
}

//TBD - check the following classes and remove those unnecessary
.bg-blue {
    background-color: #0073b7 !important;
}

.bg-green,
.callout.callout-success,
.alert-success,
.label-success,
.modal-success .modal-body {
    background-color: #00a65a !important;
}

.bg-yellow,
.callout.callout-warning,
.alert-warning,
.label-warning,
.modal-warning .modal-body {
    background-color: #f39c12 !important;
}

.text-light-yellow {
    color: #d9d200;
}

.validation-error {
    color: red
}

// .btn-success:hover, .btn-success:active, .btn-success.hover {
//     background-color: #008d4c;
// }

// .btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
//     background-image: none;
// }
// .btn-success:hover, .btn-success:focus, .btn-success.focus, .btn-success:active, .btn-success.active, .open > .dropdown-toggle.btn-success {
//     color: #ffffff;
//     background-color: #449d44;
//     border-color: #398439;
// }
// .btn-success {
//     background-color: #00a65a;
//     border-color: #008d4c;
// }


// this class adds a padding for all of the pages body 
// (to leave the space of the navigation)
.page-container {
    padding: 25px;
    padding-top: 25px;

    @media (max-width: 992px) {
        padding-top: 10px;
    }
}

//styling for the tickets (page)
// .ticket-page{
//     position: fixed !important;
//     h3 {
//         font-weight: 800 !important;
//     }
// }

.ticket-page {
    position: fixed !important;
    overflow-wrap: break-word;
    text-justify: auto;
    max-width: 330px;

    h6,
    h5,
    h4,
    h3,
    h2,
    h1 {
        width: 100%;
    }

    h6,
    h5,
    h4 {
        font-weight: 700 !important;
        font-weight: 700 !important;
    }

    h6 {
        font-size: 0.95rem !important;
        font-weight: 700 !important;
    }

    h5 {
        font-size: 1.1rem !important;
        font-weight: 700 !important;
    }

    h4 {
        font-size: 1.4rem !important;
        font-weight: 700 !important;
    }

    h3 {
        font-size: 1.5rem !important;
        font-weight: 800 !important;
    }

    h2 {
        font-size: 1.7rem !important;
        font-weight: 800 !important;
    }

    hr {
        border-top: 2px dashed rgba(0, 0, 0, 1);
    }
}

.btns-transparent {
    button {
        color: #3F4254;
        // background-color: #ffffff;
        // border-color: #ffffff;
    }
}


// @media (max-width: 1200px) {
//     .modal-dropdown {
//         width: 500px;
//         height: 800px;
//         left: -550px;
//     }
// }

// 27.08.2022 -> changed height from 800px (fixed) to auto
@media (min-width: 992px) {
    .modal-dropdown {
        width: 500px;
        height: auto;
        left: -550px;
    }
}

@media (max-width: 992px) {
    .modal-dropdown {
        width: 500px;
        height: auto;
        left: -350px;
    }
}

@media (max-width: 576px) {
    .modal-dropdown {
        width: 350px;
        height: auto;
        left: 0px;
    }
}

.cursorpointer {
    cursor: pointer;
}

.navi .navi-item .navi-link.navi-link-dropdown {
    width: 100%;
    display: inline-block;
    // background-color: #00a65a;
    border-radius: 15px;
    // padding: 0;
    button {
        width: 100%;
        padding: 15px 0;
        display: inline-block !important;
    }
}

.dropdown-menu{
    background: transparent;
    box-shadow: none;
}


.text-small-info {
    font-size: 0.5rem;
    letter-spacing: 0.05rem;
    // font-weight: 800 !important;
}

.table-title {
    max-width: 100px;
}

.hide-button {
    display: none;
}

.label-no-margin-button {
    margin-bottom: 0px;
}

.mobile-no-show {
    @media (max-width: 992px) {
        display: none;
    }
}

.desktop-no-show {
    @media (min-width: 992px) {
        display: none;
    }
}

.card-body-sm-padding {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0 5px 0 5px;
}

//class to edit the headers in the Tables of ReportMatix
.table.table-head-report {
    thead {
        height: 62px;

        th {
            padding-top: 5px;
            padding-bottom: 5px;
            font-size: 1.2rem;
        }
    }
}

//modification to the existing class
.table.table-head-custom {
    thead {
        th {
            color: #35353c !important;
        }
    }
}

//     html,
//     body {
//             font-size: 25px !important;
//     }
// }


//app.css linie 83131
// .ticket-page {
//     // @media print {
//             @media (max-width: 991.98px) {

//         font-size: 15px !important;
//     }
// }

.container-flex {
    display: flex;
    align-items: center;
}


/* Novaloans Customization */
.transition-ease {
    transition: all 0.2s ease-in-out;
  }
  
  .topbar-item-user {
    padding: 0.75rem 1.25rem;
  }
  
  .text-underline-style {
    text-decoration: underline;
  }
  
  
  
  .profile-image-layout {
    position: relative;
  }
  
  .profile-image-layout > .edit-photo-button {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  
  .profile-image-custom {
    width: 100%;
    height: 100%;
    padding: 5px;
  }
  
  
.TableFirstRow {
    width: 30%;
    text-align: center;
}

// .TableSecondRow {
//     vertical-align: top;
// }

.TableElementLongText {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    display: table-row
}

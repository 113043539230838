.nav {
    background: #000;
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    position: fixed;
    width: 100%;
}

.navlink {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    list-style-type: none;
}

.activeLink {
    //transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606 !important;
    border-radius: 6px;

    @media (max-width: 992px) {
        background: rgb(93, 93, 93);
        color: #6993FF !important;
    }

}

.navLogo {
    a {
        color: #800000;
        font-size: 1.4rem;

        &:hover {
            transition: all 0.2s ease-in-out;
            background-color: rgba(255, 255, 255, 0.1);
            color: trans;
        }

        img {
            height: 40px;
        }
    }

    @media (max-width: 768px) {
        margin: 7px 0 0 10px;
        //add styling for mobile
        a img {
            height: 30px;
        }
    }
}


.form-control {

}

.dropdown-form-control {
    display: contents;
    // select{
    //     flex: auto;
    // }
}
// import from ListCustomerBySearch

.input-label-above {
    // background-color: blue;
    // width: 100%;
    label, input, textarea {
        width: 100%;
        display: block;
    }
}

.error-text {
    color: red;
}

.error-box {
    border: 1px solid red !important;
}

.error-input {
    border: 1px solid red !important;
}

.display-hidden {
    visibility: hidden;
}

.display-show {
    visibility: visible;
}

.error-bullet-point {
    list-style-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23F64E60%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23F64E60%27 stroke=%27none%27/%3e%3c/svg%3e");
}
// .grid-container {
//     width: 100%;
//     height: $standard-grid-height;
//     margin: 5px 0px;
// }

// .column-width-100 {
//     width: 100%;
// }

// .column-width-50 {
//     width: 50%;
// }

.pageitem1 { grid-area: header; }
.pageitem2 { grid-area: menu; }
.pageitem3 { grid-area: main; }
.pageitem4 { grid-area: right; }
.pageitem5 { grid-area: footer; }
.pageitemHeader { grid-area: header; }
.pageitemMain { grid-area: main; }
.pageitemFooter { grid-area: footer; }

.grid-pagecontainer {
  display: grid;
  grid-template:
    'header header header header header header'
    'main main main main main main'
    'footer footer footer footer footer footer';
  grid-gap: 10px;
  //background-color: #2196F3;
  padding: 10px;
}

.grid-pagecontainer > div {
  // background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  font-size: 30px;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 30px;
    //background-color: #8cacc7;
    //padding: 10px;
}

.grid-container > div {
    // background-color: rgba(255, 255, 255, 0.8);
    text-align: left;
    padding: 10px 0;
    font-size: 20px;
}

.grid-container-form {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    label {
        display: contents;
    }
}

.grid-subcontainer {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
    //background-color: #8cacc7;
    padding: 10px;
}

.grid-subcontainer > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: left;
    padding: 10px 0;
    font-size: 20px;
    //max-width: 400px;
}

.grid-container-two-columns {
    padding: 40px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    //background-color: #8cacc7;
    //padding: 10px;
}

.grid-container-two-columns > div {
    // background-color: rgba(255, 255, 255, 0.8);
    text-align: left;
    padding: 10px 0;
    font-size: 20px;
    //max-width: 400px;
}

.pageitemheader1 { grid-area: header1; }
.pageitemmain1 { grid-area: main1; }
.pageitemheader2 { grid-area: header2; }
.pageitemmain2 { grid-area: main2; }
.pageitemheader3 { grid-area: header3; }
.pageitemmain3 { grid-area: main3; }
// .pageitemFooter { grid-area: footer; }

.grid-container-reports {
    display: grid;
    grid-template:
      'header1 header2 header2 header2'
      'main1 main2 main2 main2';
    // grid-gap: 10px;
    //background-color: #2196F3;
    padding: 10px;
  }

  .grid-container-reports-columns {
    padding: 2px;
    display: grid;
    grid-template-columns: auto auto auto auto auto ;
    // grid-auto-columns: 50px;
  }
  
  .grid-container-reports-columns > div {
    // background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 2px 0;
    font-size: 30px;
  }
  .pageitemmain1 > div {
    // background-color: rgba(255, 255, 255, 0.8);
    text-align: left;
    padding: 2px 0;
    font-size: 30px;
  }
  .pageitemheader1 .pageitemmain1 {
    width: 150px;
  }


.info-box {
    display: block;
    min-height: 90px;
    background: #fff;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    margin-bottom: 15px;
}

.info-box-icon {
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px;
    display: block;
    float: left;
    height: 90px;
    width: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    background: rgba(0, 0, 0, 0.2);
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.info-box-content {
    padding: 5px 10px;
    margin-left: 90px;
}

.info-box-text {
    text-transform: uppercase;
}

.info-box-number {
    display: block;
    font-weight: bold;
    font-size: 18px;
}

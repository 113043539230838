// Not sure if being used
.listview-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.list-view-container {
    width: 100%;
    height: 100%;
    margin: 0;
}

.listview-header, .listview-footer, .listview-content {
    width: 100%;
}

.listview-header {
    display: flex;
    margin: 10px 0px; 
    // background-color: aquamarine;
}

.listview-content {
    height: 90%;
    // background-color:blueviolet

    .selected-row {
        background-color: $selected-row;
    }
}

.listview-footer {
    background-color: aquamarine;
    display: flex;
    justify-content: center;
}


// new area

.container-list-view {
    width: 98%;
    height: 35vh; 
    margin: auto;
    border: 1px solid grey;
    border-radius: 5px;
    overflow: scroll;
    // padding: 0 25px;

    .footer-list-view {
        width: 100%;
        margin-top: 15px;
    }
}

.header-list-view, .header-graphic-view {
    background-color: aliceblue;
    width: 100%; 
    display: flex;
    justify-content: space-between;

    a {
        margin: 0 5px; 
    }
}

.table-list-view {
    width: 100%; 
    height: 90%;

    ul:nth-child(odd) {
        background-color: #E1E0E4;
    }

    .table-headers, .table-item {
        display: flex;
        justify-content: space-between;
        list-style: none;
        width: 100%;
        margin-bottom: 3px;
        // padding-left: 10px; 

        li {
            margin: 0;
            // padding: 0;
            display: flex;
            width: 20%;
            max-width: 25%;
            // background-color: azure;
            overflow: hidden; 
            padding: 0 5px; 
        }

    }

    .table-headers {
        li {
            font-weight: bold;
            background-color: lightgray;
            border: 1px grey solid;
            display: flex;
            justify-content: space-between;
        }
    }
}

.search-header {
    width: 100%; 
    display: flex;
    justify-content: right;
    padding: 0px 5px;
}

.left-outer-space {
    margin-left: 15px;
}

.right-outer-space {
    margin-right: 15px;
}

.upper-outer-space {}

.lower-outer-space {}

.underline-text {
    text-decoration: underline;
}

.container-graphic-view {
    width: 98%;
    min-height: 35vh;
    height: fit-content;
    margin: auto;
    border: 1px solid grey;
    border-radius: 5px;
    
    .header-graphic-view {

    }
}

.graphic-list-view {
    width: 100%;
    // height: 35vh;
    margin: 0 auto;
    padding: 10px;

    img {
        display: flex;
    }
}

.table-list-buttons{
    form{
        display: contents;
    }
}

.row-select {
    background: #ADDDEE !important;
}
.sidebar-layout {
    align-items: center;
    height: 100vh;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    padding: 25px;
}

.sidebar-navigation-container {
    grid-column-start: 1;
    grid-column-end: 2;
    height: 100%;
}

.sidebar-content-container {
    grid-column-start: 2;
    grid-column-end: 8;
    height: 100%;
}


// Standard colours
$success:                   #2e7d32;
$error:                     #FF1A2F;
$warning:                   #FFA026;
$default:                   #889195;
$primary:                   #4481F1;
$secundary:                 #52abd1;


// Grid container metrics
$standard-grid-height:  25px;


// List view widget
$selected-row:              #E4DECC;


// Tab view controls
$tab-border:              rgba(99, 99, 99, 0.2);
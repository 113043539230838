.search-form {
    max-width: 460px;
    display: flex;
    padding-bottom: 10px;

    input {
      font-size: 1em;
      width: 100%;
      background-color: #edeff0;
      padding: 5px 15px;
      border: 3px solid #d7dbdf;
      border-right: none;
      border-radius: 0.35em 0 0 0.35em;
      outline: none;
    }

    button {
      outline: none;
      background-color: grey;
      border: none;
      padding: 0px 15px;
      border-radius: 0 0.35em 0.35em 0;
      cursor: pointer;
    }
}
.carusel-item-card{
    display: flex ; 
    justify-content: center;
    align-items: center;
    height: 350px;
    width: 100%;
    background-color: #7FB202;
    color: #fff;
    margin: 0 15px;
    //font-size: 2em;
    border-radius: 10px;
}

.carusel-item-title{
    display: flex;
    font-size: 2.5em;
    color: black; 
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.carusel-item-subtitle{
    display: flex;
    font-size: 1.5em;
    color: white; 
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.carusel-item-buttons{
    display: flex;
    justify-content: center;
    align-items: center;
}

.carusel-item-percentage{
    display: flex;
    font-size: 3.5em;
    color: black; 
    justify-content: center;
    align-items: center;
    padding: 5px;
}
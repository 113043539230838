.gallery-image {
    display: inline-block;

    img {
        width: 150px;
        height: 150px;
        margin: 0 10px 10px 0;
    }
}

.profile-image {
    width: auto;
}

.thumbnail {}

// .image-small {}

// .image-medium {}

// .image-large {}

.image-detail {
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.icon-alarm, .icon-warning, .icon-good {
    margin: auto; 
}

.icon-alarm {
    svg {
        color: red !important;
    }
}

.icon-warning {
    svg {
        color: orange !important;
    }
}

.icon-good {
    svg {
        color: green !important;
    }
}

.icon-info {
    width: 50px;
    height: 50px; 
}

.icon-small {
    width: 25px;
    height: 25px;
}

.icon-mini {
    width: 15px;
    height: 15px;
}

.icon-info:hover + .status-icon {
    visibility: visible;
}

.status-icon, .complete-hide {
    // position: absolute;
    // z-index: 10;
    visibility: hidden;
    position: absolute;
    z-index: 10;
    width: fit-content; 
    background: rgb(255, 255, 255); 
    border: 1px solid rgb(187, 187, 187); 
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px; 
    padding: 5px; 
    // top: 255.312px; 
    left: 0px;
    top: 100px;
}

.good {
    color: $success;
}

.warning {
    color: $warning;
}

.alarm {
    color: $error;
}

// .complete-hide {
//     visibility: hidden;
// }

// .status-icon:hover {
//     display: block;
// }



// for hover pop up
// position: absolute; 
// z-index: 2; 
// width: 200px; 
// background: rgb(255, 255, 255); 
// border: 1px solid rgb(187, 187, 187); 
// box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px; 
// padding: 5px; top: 255.312px; 
// left: 317.141px;

// for input box
// position: absolute;
// z-index: 2;
// width: 200px;
// background: rgb(255, 255, 255);
// border: 1px solid rgb(187, 187, 187);
// box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px;
// padding: 5px;
// top: 255.312px;
// left: 317.141px;
/* Login Page styling */

.login-background-right {
    background-image: url(../../images/bg-4.jpg);
  }
  
  .login-background-full {
    background-image: url(../../images/bg-1.jpg);
  }
  
  .login.login-3 .login-signin,
  .login.login-3 .login-signup,
  .login.login-3 .login-forgot {
    display: none;
  }
  
  .login.login-3.login-signin-on .login-signup {
    display: none;
  }
  
  .login.login-3.login-signin-on .login-signin {
    display: block;
  }
  
  .login.login-3.login-signin-on .login-forgot {
    display: none;
  }
  
  .login.login-3.login-signup-on .login-signup {
    display: block;
  }
  
  .login.login-3.login-signup-on .login-signin {
    display: none;
  }
  
  .login.login-3.login-signup-on .login-forgot {
    display: none;
  }
  
  .login.login-3.login-forgot-on .login-signup {
    display: none;
  }
  
  .login.login-3.login-forgot-on .login-signin {
    display: none;
  }
  
  .login.login-3.login-forgot-on .login-forgot {
    display: block;
  }
  
  .login.login-3 .login-form {
    width: 100%;
    max-width: 450px;
  }
  
  @media (max-width: 575.98px) {
    .login.login-3 .login-form {
      width: 100%;
      max-width: 100%;
    }
  }

  .bg-dark-o-70 {
    background-color: rgba(24,28,50,.28)!important;
  }
  
  .opacity-70 {
    opacity: .7!important;
  }

  